import React from 'react'
import Pagina from '../components/Pagina'
import { graphql } from 'gatsby'
import CardBlog from '../components/blog/card/blogCard'
import BannerLwart from '../components/BannerLwart'
// import Img from 'gatsby-image'

const Blog = ({ data }) => {
  const posts = data.allPosts.nodes.filter(post => post.title !== 'Expansion: Lwart will become the second largest re-refinery in the world!')
  return <>
    <Pagina pagina={data.pagina} />
    <BannerLwart
      desktop={data.paralax.childImageSharp.gatsbyImageData}
      mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
      titulo='Já conhece o nosso blog?'
      texto="É um espaço criado para compartilhar tudo o que temos feito e falar sobre assuntos relacionados a sustentabilidade, ecoeficiência e economia circular."
      className="bannerImage"
    />
    <div className="container my-5">
      <div className="row">
        {
          posts.map(post => {
            return (
              <div className="col-lg-6 col-md-12 mb-3">
                <CardBlog
                  image={post.cardImage.localFile.childImageSharp.gatsbyImageData}
                  alt={post.cardImage.description}
                  description={post.description.description}
                  title={post.title}
                  author={{ nome: post.author.nome, slug: post.author.slug }}
                  readTime={post.readTime}
                  date={post.date}
                  postLink={post.slug}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  </>
}

export default Blog

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/blog/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "blog/header.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "blog/blog.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 400, height: 350, layout: CONSTRAINED)
    }
  }
  allPosts: allContentfulBlogPost(
    filter: {node_locale: {eq: "pt-BR"}}
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      node_locale
      title
      slug
      description {
        description
      }
      tags
      date(formatString: "DD/MM/YYYY")
      readTime
      cardImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 455, height: 300, layout: CONSTRAINED)
          }
        }
      }
      author {
        nome
        slug
        admin
      }
    }
  }
}
`

// export default injectIntl(NotFoundPage)
